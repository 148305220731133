import { VOYAGER } from '@carto/react-basemaps';
import { API_VERSIONS } from '@deck.gl/carto';

export const initialState = {
  viewState: {
    latitude: -37.816112823047064,
    longitude:  144.95329191939527,
    zoom: 15,
    pitch: 60,
    bearing: 0,
    dragRotate: true,
  },
  basemap: VOYAGER,
  credentials: {
    apiVersion: API_VERSIONS.V3,
    apiBaseUrl: 'https://gcp-us-east1.api.carto.com',
    accessToken: 'eyJhbGciOiJIUzI1NiJ9.eyJhIjoiYWNfN3hoZnd5bWwiLCJqdGkiOiIxYTU4YTUyZSJ9.MKmoLIREDkM-fcNomKysra5Z_x5HW0DmMeR851SVA9Y'
  },
  googleApiKey: '', // only required when using a Google Basemap
  googleMapId: '', // only required when using a Google Custom Basemap
  /*
  oauth: {
    domain: 'auth.carto.com',
    // Type here your application client id
    clientId: 'r0sb7lvlp2rt2Ok2wOQ5uSkLIbfrznFy',
    scopes: [
      'read:current_user',
      'update:current_user',
      'read:connections',
      'write:connections',
      'read:maps',
      'write:maps',
      'read:account',
      'admin:account',
    ],
    audience: 'carto-cloud-native-api',
    authorizeEndPoint: 'https://carto.com/oauth2/authorize', // only valid if keeping https://localhost:3000/oauthCallback
  },
  */
};
